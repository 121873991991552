import React, { Component } from "react"
import PropTypes from "prop-types"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import NotFound from "../components/NotFound/NotFound"
import { renderTags } from "../utilities/renderTags"
import { graphql } from "gatsby"
import Template from "../components/Template/Template"

export const pageQuery = graphql`
  {
    image: file(relativePath: { eq: "bgimage404.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    image404: file(relativePath: { eq: "404.png" }) {
      childImageSharp {
        gatsbyImageData(width: 276, height: 112, layout: CONSTRAINED)
      }
    }
    contentfulPage(slug: { eq: "404" }) {
      ...pageFields
    }
  }
`
export default class NotFoundPage extends Component {
  page = this.props.data.contentfulPage
  componentDidMount() {
    this.addGoogleAnalytics()
    this.addGoogleAnalyticsEvent()
  }

  addGoogleAnalytics() {
    const script = document.createElement("script")
    const scriptBody = `var global_clientId_analytics;
    (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
    (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date(); a = s.createElement(o),
    m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

    ga('create', 'UA-79552282-1', 'auto', { 'allowLinker': true });
    ga('create', 'UA-79552282-3', { 'name': 'rollupProperty', 'allowLinker': true });

    ga('require', 'linker');
    ga('linker:autoLink', ['app.agicap.fr', 'content.agicap.fr', 'app.agicap.com', 'agicap.com']);
    ga('rollupProperty.require', 'linker');
    ga('rollupProperty.linker:autoLink', ['app.agicap.fr', 'content.agicap.fr', 'app.agicap.com', 'agicap.com']);

    ga('rollupProperty.require', 'GTM-594HVRW');

    ga('send', 'pageview');
    ga('rollupProperty.send', 'pageview');

    ga(function (tracker) {
    global_clientId_analytics = tracker.get('clientId');
    });`
    script.innerHTML = scriptBody
    document.body.appendChild(script)
  }

  addGoogleAnalyticsEvent() {
    const script = document.createElement("script")
    const pathName = this.props.location.pathname
    const data = {
      hitType: "event",
      eventCategory: "Website Issue",
      eventAction: "404",
      eventLabel: pathName
    }
    const dataString = JSON.stringify(data)
    script.innerHTML = `ga('send', ${dataString});`
    document.body.appendChild(script)
  }

  pageStartScriptTags = renderTags("en", "en", this.page.bodyStartScripts)
  pageEndScriptTags = renderTags("en", "en", this.page.bodyEndScripts)
  // eslint-disable-next-line no-undef
  hasFixedMenu = _.defaultTo(_.get(this.page, "fixedMenu"), true)
  render() {
    const {
      image: { childImageSharp }
    } = this.props.data
    const topMenuData = this.props.data.contentfulPage.template
    return (
      <Layout>
        <SEO lang="en" title="404: Not found" />
        <NotFound
          image={childImageSharp}
          image404={this.props.data.image404.childImageSharp}
          titleA="This page does not exist"
          // titleB="Let's get rid of this sheet."
          ctaURL="/"
          type="new"
          locale="en"
          defaultLocale="en"
          linkHome={{
            targetUrl: "/",
            title: "Go to home page",
            displayStyle: "secondaryButton"
          }}
        />
        <Template
          {...topMenuData}
          isMenuFixed={this.props.data.contentfulPage.fixedMenu}
          locale={"en"}
          defaultLocale={"en"}
        >
          {this.pageStartScriptTags}
          {this.pageEndScriptTags}
        </Template>
      </Layout>
    )
  }
}
NotFoundPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
}
